import React from 'react';
import {connect, useDispatch} from "react-redux"
import styles from "../../../components/innerPage.module.css"
import Footer from '../../../components/NonUkResidents/HCP/footer';
import Header from '../../../components/NonUkResidents/HCP/header';
import Alkindi from "../../../images/alkindi_cmyk.png"
import Pdf from "../../../images/pdf_icon.png"
import UkImg from "../../../images/uk_flag.png"
import German from "../../../images/german.png"
import Czech from "../../../images/czech-flag.png" 
import { Icon } from 'react-icons-kit'
import {ic_picture_as_pdf} from 'react-icons-kit/md/ic_picture_as_pdf'
import {link} from 'react-icons-kit/icomoon/link'
import { Link, navigate, graphql } from "gatsby";
import PdfFile from "../../../images/uk_prescribing-information_version-4-pp.pdf"
import ScrollAnimation from 'react-animate-on-scroll';
import {showNonUkResidentsHcpDisclaimerPopup} from "../../../actions/nonUkResidents/hcp"
import {deriveHomePathFromSpace, isNullUndefined, addDisclaimersToCmsLinks, richTextOptions, capitalizeFirstLetter} from "../../../util"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { getDefaultNonUkResidentsHcpDisclaimerPopupConfigs, config, getNonUkResidentsNonHcpGermanInfoDisclaimerPopupConfigs, getNonUkResidentsNonHcpGermanInfoDisclaimerPopupConfigsEfmody } from '../../../util/configs'
import queryString from "query-string"
import SEO from "./../../../components/seo"
import TemplateWrapper from "../../../components/TemplateWrapper"
import {enforceLoggedInCheckForGermanPrescribers, enforceLoggedInCheckForGermanPrescribersEfmody} from "../.../../../../controllers/nonUkResidents/hcp/doccheck"

export const pageQueryCountryInformationTemplateNonUkHcp = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulCountryInformationTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
                bannerImage {
                    file {
                        url
                    }
                }
                bodyText {
                    json
                }
                spaceId 
                slug
                title
                parentMenu {
                    country_information_template {
                      parentMenu {
                        title
                        slug
                      }
                    }
                }
                video_pdf_template {
                    title
                    flag {
                      file {
                        url
                      }
                    }
                    slug
                }
        } 
    }
`

const CountryInformationTemplatePageNonUkHcp = (props) => {
    const dispatch = useDispatch()

    const {bannerImage, title, spaceId, bodyText, parentMenu, video_pdf_template} = props.data.contentfulCountryInformationTemplate;

    let richText = documentToReactComponents(isNullUndefined(bodyText) ? bodyText : bodyText.json, richTextOptions)

    let bodyHtml = ReactDOMServer.renderToStaticMarkup(richText)

    const showExternalLinkDisclaimer = (externalUrl) => {
        let okCallback = () => window.open (externalUrl, "_blank")
        let closeCallback = () => {}
        let actionConfig = getDefaultNonUkResidentsHcpDisclaimerPopupConfigs(okCallback, closeCallback)
        dispatch(showNonUkResidentsHcpDisclaimerPopup(actionConfig));
    }

    const togglePopup = (path, slug) => {
        let okCallback = async () => await navigate(path)
        let closeCallback = async () => {}
        let popupConfig = {}

        switch(slug){
            case "information-for-german-prescribers":
                okCallback = async () => {
                    let temporaryCode = localStorage.getItem("gatsby_doccheck_temporary_code");
                    await enforceLoggedInCheckForGermanPrescribers(temporaryCode);
                };
                popupConfig = getNonUkResidentsNonHcpGermanInfoDisclaimerPopupConfigs(okCallback, closeCallback);
                localStorage.setItem("nonukresidents-hcp-german-prescribers", JSON.stringify({ disclaimerShown: true }));

                break;
            case "information-for-german-prescribers-for-efmody":
                okCallback = async () => {
                    let temporaryCode = localStorage.getItem("gatsby_doccheck_temporary_code");
                    await enforceLoggedInCheckForGermanPrescribersEfmody(temporaryCode);
                };
                popupConfig = getNonUkResidentsNonHcpGermanInfoDisclaimerPopupConfigsEfmody(okCallback, closeCallback);
                localStorage.setItem("nonukresidents-hcp-german-prescribers", JSON.stringify({ disclaimerShown: true }));

                break;
            default:
                navigate(path);
                return;
        }
        dispatch(showNonUkResidentsHcpDisclaimerPopup(popupConfig));
    }
    let [veevaDisclaimer, setVeevaDisclaimer] = React.useState(false);


    React.useEffect(() => {
        let {veevaDisclaimer} = queryString.parse(props.location.search || "");
  
        if(veevaDisclaimer){
          setVeevaDisclaimer(true);
          showExternalLinkDisclaimer(config.defaultBaseUrl)
        }
  
    }, []);

    console.log("logging");

    return ( 
       <TemplateWrapper>
           <Header/>
         <SEO title={`${capitalizeFirstLetter(title)} | Non-UK Residents | HCP`} />
            
          
                  <div className={styles.banner} style={{background: `url(${isNullUndefined(bannerImage) ? "":bannerImage.file.url})`}}>
                  <div className={styles.overlay}></div>
                            <div className="container">
                               <div className="row">
                                   <div className="col-12 col-sm-12 col-md-12">
                                    <h1 className="">
                                       
                                       {title}</h1>
                                
                                    <ol className="custom_breadcrumb">
                                    <li className="breadcrumb-item"><Link to={`/NonUkResidents/HCP/home`}>Home</Link></li>
                                    {!isNullUndefined(parentMenu) && !isNullUndefined(parentMenu.country_information_template) && <li className="breadcrumb-item"><a href="#">
                                   {parentMenu.country_information_template[0].parentMenu.title}    
                                         </a></li>}
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {title}
                                        </li>
                                    </ol>
                                    </div>
                                   </div>
                            </div>
                </div>

                <div className={styles.content}>
                   <div className="container">
                 <div className="row">
                 <div className={` col-12 ${styles.prescribing_information_nonhcp}`}>

                 <ScrollAnimation animateIn="fadeIn">
                    {ReactHtmlParser(bodyHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}
                   </ScrollAnimation>

                </div>
                   
                   
                    </div>
                   </div>
                </div>

                <div className={styles.flags_section}>
                    <div className="container pt-5 pb-5">
                    <div className="row">
                    {video_pdf_template?.map((item, index) => {
                      return (
                        <div key={index} className="col-12 col-sm-12 col-md-4">
                            <ScrollAnimation animateIn="fadeIn">
                            <div className={styles.content_section}>
                                <div className={styles.flag}>
                                <img src={!isNullUndefined(item.flag) && item.flag.file.url}/>
                                </div>
                                <h3>{item.title}</h3>
                            <a onClick={() => togglePopup(`/NonUkResidents/HCP/${item.slug}`, item.slug)}><button className="custom_btn dark bordered_btn">Read more</button></a> 
                        </div>
                        </ScrollAnimation>
                        </div>
                      )
                  })}
                    </div>
                    </div>
                </div>

                <Footer/>
       </TemplateWrapper>
     );
}

export default CountryInformationTemplatePageNonUkHcp;